import React, { Component } from 'react';

import './BaseDialog.css';

import Animated from "animated/lib/targets/react-dom";

export default class extends Component {

    static defaultProps = {
        justifyContent: 'center',
        alignItems: 'center',
        translateY: true,
        top: 0,
        coverClick: false,
        zIndex: 999
    }

    _path = new Animated.Value(0);

    state = {
        _show: false
    }

    dismiss = (callback = null) => {
        Animated.timing(this._path, { toValue: 0, duration: 150 }).start(() => {
            this.setState({ _show: false });
            callback && callback();
        });
    }

    show = () => {
        this.setState({ _show: true }, () => {
            Animated.timing(this._path, { toValue: 1, duration: 150 }).start(() => {
                this.setState({ _show: true });
            });
        })
    }

    _show = () => {
        this.setState({ _show: true }, () => {
            Animated.timing(this._path, { toValue: 1, duration: 150 }).start(() => {
                this.setState({ _show: true });
            });
        })
    }

    renderContent() {

    }

    getTransform = () => {
        return {
            translateY: this.props.translateY === false ? 0 : this._path.interpolate(
                {
                    inputRange: [0, 0.01, 1],
                    outputRange: [300, 300, 0]
                }
            )
        };
    }

    render() {
        if (this.state._show) {
            const backgroundColor = this.props.backgroundColor !== undefined ? { backgroundColor: this.props.backgroundColor } : {};
            return <Animated.div
                onClick={() => {
                    if (this.contentClick) {
                        this.contentClick = false;
                    } else {
                        if (this.props.coverClick) {
                            this.dismiss();
                        }
                    }
                }}
                className="BaseDialog"
                style={{
                    ...backgroundColor,
                    display: 'flex', flexDirection: 'column',
                    opacity: this._path.interpolate({
                        inputRange: [0, 0.5, 1],
                        outputRange: [0, 1, 1]
                    }), justifyContent: this.props.justifyContent, alignItems: this.props.alignItems,
                    top: this.props.top,
                    zIndex: this.props.zIndex
                }}>
                <Animated.div
                    onClick={() => {
                        this.contentClick = true;
                        return true;
                    }}
                    style={{
                        pointerEvents: 'auto',
                        transform: [
                            this.getTransform()
                        ]
                    }}>
                    {this.renderContent()}
                </Animated.div>
            </Animated.div>
        } else {
            return null;
        }
    }
}


