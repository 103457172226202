import React, { Component } from 'react';

import { HashRouter, Route } from 'react-router-dom';

import HomePage from './pages/HomePage';


export default class extends Component {

  render() {
    return <HashRouter>
      <Route path='/' component={HomePage} exact />
    </HashRouter>
  }
}


