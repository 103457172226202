import React from 'react';

import './BaseDialog.css';

import Spinkit from 'react-spinkit';

import BaseDialog from './BaseDialog';

export default class extends BaseDialog {

    getTransform = () => {
        return {
            
        };
    }

    renderContent() {
        return <Spinkit name='line-scale' color='#FF3355' fadeIn='quarter' />
    }

}