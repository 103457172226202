import React from 'react';

import BasePage from './BasePage';

import icon1 from '../assets/icon1.svg';

import icon2 from '../assets/icon2.svg';

import icon3 from '../assets/icon3.svg';

import website_logo from '../assets/website_logo.png';

import banner from '../assets/banner.png';

import iber from '../assets/iber.jpg';

import fjtea from '../assets/fjtea.jpg';

import aliyun from '../assets/aliyun.jpg';

import tctiming from '../assets/tctiming.png';

import shitang from '../assets/shitang.jpg';

import bsnl from '../assets/bsnl.jpg';

import pto from '../assets/pto.jpg';

import mpt from '../assets/mpt.jpg';

import libiya from '../assets/libiya.jpg';

import jsjd from '../assets/jsjd.jpg';

import mtn from '../assets/mtn.jpg';

export default class extends BasePage {

    state = {
        index: 0
    }

    case = [
        { name: '金山街道综合网格治理平台', img: jsjd },
        { name: '蚂蚁集团-福茶网APP', img: fjtea },
        { name: '阿里云-专有钉', img: aliyun },
        { name: 'iBer', img: iber },
        { name: '天成时Ke', img: tctiming },
        { name: '智慧食堂', img: shitang },
        { name: '印度电信运营商BSNL', img: bsnl },
        { name: '菲律宾电信运营商PTO', img: pto },
        { name: '缅甸运营商MPT', img: mpt },
        { name: '卢旺达电信运营商MTN', img: mtn },
        { name: '利比亚电信运营商FMX', img: libiya }
    ]

    selectStyle = {
        flexShrink: 0, paddingLeft: '2.8vw', paddingRight: '2.8vw', lineHeight: '8vw', fontSize: '4vw', color: '#2f80ed', marginLeft: '2.8vw',
        borderBottomColor: '#2F80ED', borderBottomStyle: 'solid', borderBottomWidth: '0.8vw'
    }

    normalStyle = { flexShrink: 0, paddingLeft: '2.8vw', paddingRight: '2.8vw', lineHeight: '8vw', fontSize: '4vw', color: '#BDBDBD', marginLeft: '2.8vw' }

    render() {
        const year = (new Date()).getFullYear();
        return <div style={{ width: '100vw', height: '100vh', backgroundColor: '#fff' }}>
            <img style={{ width: '100vw', height: '53.33vw', backgroundColor: '#ccc' }} src={banner} />
            <div style={{ width: '100vw', display: 'flex', justifyContent: 'space-around', marginTop: '8vw' }}>
                <div style={{
                    width: '28.26vw', height: '32vw', background: 'linear-gradient(271.49deg, #D1E6FC 1.4%, rgba(224, 239, 255, 0.41) 98.92%)',
                    borderRadius: '1.6vw', position: 'relative', display: 'flex', flexDirection: 'column'
                }}>
                    <div style={{
                        width: '9.6vw', height: '9.6vw', backgroundColor: '#2f80ed', borderRadius: '100%', position: 'absolute', left: '9vw', top: '-5vw',
                        display: 'flex', justifyContent: 'center', alignItems: 'center'
                    }}>
                        <img style={{ width: '5vw', height: '5vw' }} src={icon1} />
                    </div>
                    <div style={{ width: '28.26vw', textAlign: 'center', marginTop: '6.4vw', color: '#2f80ed', fontSize: '3.73vw', fontWeight: 'bold' }}>整体解决方案</div>
                    <div style={{ width: '28.26vw', textAlign: 'center', marginTop: '1.2vw', color: '#2f80ed', fontSize: '3vw' }}>商业模式</div>
                    <div style={{ width: '28.26vw', textAlign: 'center', marginTop: '1.2vw', color: '#2f80ed', fontSize: '3vw' }}>智慧硬件</div>
                    <div style={{ width: '28.26vw', textAlign: 'center', marginTop: '1.2vw', color: '#2f80ed', fontSize: '3vw' }}>运营策略</div>
                </div>
                <div style={{
                    width: '28.26vw', height: '32vw', background: 'linear-gradient(270deg, #FFBD82 0%, rgba(255, 221, 190, 0.49) 104.76%)',
                    borderRadius: '1.6vw', position: 'relative', display: 'flex', flexDirection: 'column'
                }}>
                    <div style={{
                        width: '9.6vw', height: '9.6vw', backgroundColor: '#F2994A', borderRadius: '100%', position: 'absolute', left: '9vw', top: '-5vw',
                        display: 'flex', justifyContent: 'center', alignItems: 'center'
                    }}>
                        <img style={{ width: '5vw', height: '5vw' }} src={icon2} />
                    </div>
                    <div style={{ width: '28.26vw', textAlign: 'center', marginTop: '6.4vw', color: '#F66A1B', fontSize: '3.73vw', fontWeight: 'bold' }}>软件开发</div>
                    <div style={{ width: '28.26vw', textAlign: 'center', marginTop: '1.2vw', color: '#F66A1B', fontSize: '3vw' }}>互联网平台</div>
                    <div style={{ width: '28.26vw', textAlign: 'center', marginTop: '1.2vw', color: '#F66A1B', fontSize: '3vw' }}>政企应用</div>
                    <div style={{ width: '28.26vw', textAlign: 'center', marginTop: '1.2vw', color: '#F66A1B', fontSize: '3vw' }}>行业应用</div>
                </div>
                <div style={{
                    width: '28.26vw', height: '32vw', background: 'linear-gradient(270deg, #6FCF97 2.36%, rgba(111, 207, 151, 0.53) 97.64%)',
                    borderRadius: '1.6vw', position: 'relative', display: 'flex', flexDirection: 'column'
                }}>
                    <div style={{
                        width: '9.6vw', height: '9.6vw', backgroundColor: '#219653', borderRadius: '100%', position: 'absolute', left: '9vw', top: '-5vw',
                        display: 'flex', justifyContent: 'center', alignItems: 'center'
                    }}>
                        <img style={{ width: '5vw', height: '5vw' }} src={icon3} />
                    </div>
                    <div style={{ width: '28.26vw', textAlign: 'center', marginTop: '6.4vw', color: '#085C2C', fontSize: '3.73vw', fontWeight: 'bold' }}>人力外包</div>
                    <div style={{ width: '28.26vw', textAlign: 'center', marginTop: '1.2vw', color: '#085C2C', fontSize: '3vw' }}>前端程序员</div>
                    <div style={{ width: '28.26vw', textAlign: 'center', marginTop: '1.2vw', color: '#085C2C', fontSize: '3vw' }}>后端程序员</div>
                    <div style={{ width: '28.26vw', textAlign: 'center', marginTop: '1.2vw', color: '#085C2C', fontSize: '3vw' }}>软件测试工程师</div>
                </div>
            </div>
            <div style={{ display: 'flex', marginTop: '7.2vw', alignItems: 'center' }}>
                <div style={{ marginLeft: '4.8vw', marginRight: '1.5vw', width: '1.6vw', height: '5.33vw', backgroundColor: '#2f80ed' }}></div>
                <div style={{ fontSize: '4.8vw', color: '#000', fontWeight: 'bold' }}>关于福建后浪科技有限公司</div>
            </div>
            <div style={{ position: 'relative', height: '70vw', marginTop: '3.73vw', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                <img style={{ width: '40vw', height: '40vw', opacity: 0.2, marginBottom: '-32vw' }} src={website_logo} />
                <div style={{ left: 0, top: 0, position: 'absolute', marginLeft: '4.8vw', marginRight: '4.8vw', lineHeight: '5.6vw', fontSize: '3.73vw', textIndent: '8vw', color: '#333' }}>
                    后浪科技成立于2020年8月，注册资本1000W。我们依托丰富的项目落地经验，致力于成为”数字化整体(软硬件)解決方案”的专业服务商。核心成员有十年以上互联网从业经验，在立项调研、资源和商业模式梳理、产品方案设计、技术研发测试、落地运营分析等环节均拥有值得信赖的服务能力。公司以“响应迅速，使命必达”为宗旨！以“客户至上”为服务理念！先后为众多知名企业和实力创业公司提供优质的服务，比如深圳港优创新(香港最大的中立保险人Saas服务平台)、天成教育集团、浩鲸云计算、中国电信、阿里云、菲律宾电信运营商PT0、非洲最大的电信运营商MTN、缅甸电信运营商MPT、印度电信运营商BSNL等等，在项目合作期间，深受客户好评和信赖！
                </div>
            </div>
            <div style={{ display: 'flex', marginTop: '15.2vw', alignItems: 'center' }}>
                <div style={{ marginLeft: '4.8vw', marginRight: '1.5vw', width: '1.6vw', height: '5.33vw', backgroundColor: '#2f80ed' }}></div>
                <div style={{ fontSize: '4.8vw', color: '#000', fontWeight: 'bold' }}>作品案例（{this.case.length}）</div>
            </div>
            <div style={{ width: '100vw', overflowX: 'scroll', display: 'flex', marginTop: '5vw' }}>
                {this.case.map((item, index) => {
                    return <div onClick={() => this.setState({ index })} key={'case' + index} style={index == this.state.index ? this.selectStyle : this.normalStyle}>{item.name}</div>
                })}
            </div>
            <div style={{ width: '100vw', height: '105vw', backgroundColor: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '5vw' }}>
                <img style={{ width: '100vw', height: '100vw', objectFit: 'contain' }} src={this.case[this.state.index].img} />
            </div>
            <div style={{ display: 'flex', marginTop: '7.2vw', alignItems: 'center' }}>
                <div style={{ marginLeft: '4.8vw', marginRight: '1.5vw', width: '1.6vw', height: '5.33vw', backgroundColor: '#2f80ed' }}></div>
                <div style={{ fontSize: '4.8vw', color: '#000', fontWeight: 'bold' }}>联系我们</div>
            </div>
            <div style={{ display: 'flex', marginLeft: '7.8vw', alignItems: 'center', marginTop: '4vw' }}>
                <div style={{ fontSize: '4.27vw', color: '#000', fontWeight: 'bold' }}>应先生</div>
                <div style={{ fontSize: '3.73vw', color: '#000', marginLeft: '1vw' }}>18965091696</div>
            </div>
            <div style={{ display: 'flex', marginLeft: '7vw', alignItems: 'center', marginTop: '4vw' }}>
                <div style={{ fontSize: '3.73vw', color: '#000', marginLeft: '1vw' }}>福建省福州市鼓楼区东街街道东街43号新都会财经广场8E单元</div>
            </div>
            <div style={{ width: '100vw', height: '40vw', flexDirection: 'column', backgroundColor: '#F2F2F9', marginTop: '8vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div onClick={() => {
                    window.location.href = 'https://beian.miit.gov.cn';
                }} style={{ fontSize: '3.2vw', color: '#828282', marginBottom: '3vw' }}>闽ICP备18012492号-1</div>
                <div style={{ fontSize: '3.2vw', color: '#828282', textAlign: 'center', lineHeight: '5vw' }}>
                    @2020-{year} RENRENF.com版权所有<br />
                </div>
            </div>
        </div>
    }

}