import React, { Component } from 'react';

import Animated from "animated/lib/targets/react-dom";

import LoadingDialog from '../components/dialog/LoadingDialog';

import Toast from '../components/Toast';

import Const from '../base/Const';

import ic_failed from '../assets/ic_failed.svg';

export default class extends Component {

    constructor(props) {
        super(props);
        if (this.props.history) {
            this._unlisten = this.props.history.listen((location, type) => {
                if (type === 'POP' && location.pathname === this.props.pathname) {
                    this.onResume && this.onResume();
                    this._setTitle();
                }
                if (type === 'PUSH' && location.pathname === this.props.pathname) {
                    this.onResume && this.onResume();
                    this._setTitle();
                }
            });
            this._setTitle();
            this.screenHeight = document.documentElement.clientHeight;
        }
    }

    componentWillUnmount() {
        this._unlisten();
        this.onDestory && this.onDestory();
    }

    push = (pageName, params) => {
        if (params) {
            let str = '';
            let first = true;
            for (let key in params) {
                str += ((first ? '?' : '&') + key + '=' + params[key]);
                first = false;
            }
            this.props.history.push('/' + pageName + str);
        } else {
            this.props.history.push('/' + pageName);
        }
    }

    replace = (pageName, params) => {
        if (params) {
            let str = '';
            let first = true;
            for (let key in params) {
                str += ((first ? '?' : '&') + key + '=' + params[key]);
                first = false;
            }
            this.props.history.replace('/' + pageName + str);
        } else {
            this.props.history.replace('/' + pageName);
        }
    }

    goBack = (stack = -1) => {
        this.props.history.go(stack);
    }

    setTitle = (title) => {
        document.title = title;
    }

    _setTitle = () => {
        if (this.props.title) {
            document.title = this.props.title;
        } else {
            if (this.getParams('target_name')) {
                document.title = this.getParams('target_name');
            }
        }
    }

    _loadingPath = new Animated.Value(0);

    state = {
        _isFailed: false
    }

    getParams = (key, defaultValue) => {
        let search = this.props.location.search;
        search = search.replace('?', '');
        let temp = search.split('&');
        let params = {};
        temp.map(item => {
            let kv = item.split('=');
            params[kv[0]] = decodeURI(kv[1]);
            return item;
        })
        if (key) {
            return params[key] ? params[key] : defaultValue
        } else {
            return params;
        }
    }

    showLoading = () => {
        if (this.LoadingDialog) {
            this.LoadingDialog.show();
        }
    }

    dismissLoading = () => {
        if (this.LoadingDialog) {
            this.LoadingDialog.dismiss();
        }
    }

    attachLoading = () => {
        return <LoadingDialog ref={ref => this.LoadingDialog = ref} />
    }

    _onRetry = () => {
        this.setState({ _isFailed: false }, this.onReload);
    }

    showFailed = () => {
        this.setState({ _isFailed: true })
    }

    showToast = (message) => {
        if (this.Toast) {
            this.Toast.makeText(message);
        }
    }

    attachFailed = () => {
        if (this.state._isFailed) {
            return <div style={{
                position: 'absolute', left: 0, top: '70px', right: 0, bottom: 0,
                display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#ccc',
            }}>
                <div style={{ width: '1150px', height: '480px', backgroundColor: '#fff', borderRadius: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img style={{ width: '200px', height: '200px', marginTop: '40px' }} src={ic_failed} />
                    <div className='title' style={{ marginTop: '40px' }}>网络请求失败，请点击重试</div>
                    <div onClick={this._onRetry} className='button buttonBg' style={{ width: '110px', height: "38px", marginTop: '60px' }}>重试</div>
                </div>
            </div>
        }
    }

    attachBackView = () => {
        return <div
            onClick={() => {
                if (this.props.history.length > 1) {
                    this.goBack();
                } else {
                    window.location.replace("/web");
                }
            }}
            className='btn'
            style={{
                width: '12vw', height: "12vw", borderRadius: '6vw', position: 'fixed',
                left: '2vw', bottom: '40vh', zIndex: 999, backgroundColor: 'rgba(0,0,0,0.5)',
                display: 'flex', justifyContent: 'center', alignItems: 'center'
            }}>
            <img style={{ width: '8vw', height: '8vw' }} alt='' src={Const.IMG_DOMAIN + 'back.svg'} />
        </div>
    }

    attachToast = () => {
        return <Toast ref={ref => this.Toast = ref} />
    }
}


