export default class Const {

    static _DEV = process.env.NODE_ENV;

    static _PRODUCT_HOST = 'https://app.tc-timing.com';

    static _HOST = (document.domain === 'app.tc-timing.com') ? Const._PRODUCT_HOST : 'https://test.tc-timing.com';

    static _SERVER = Const._HOST + '/api/';

    static IMG_DOMAIN = 'https://tc-timing.oss-cn-shanghai.aliyuncs.com/mp/';

    static _REQUEST_LOGIN_TIMEOUT = 10001;

    static _TITLE = '天成时Ke-企业商学院一站式解决方案'

    //用户注册及隐私协议
    static _PRIVACY_POLICY_URL = Const._HOST + '/index/index/page?alias=privacy';

    static _PROVICY_AGENT_VIP_URL = Const._HOST + '/index/index/page?alias=agent_vip';

    static MP_VERSION_NAME = 'v2.2.0';
}
