import React, { Component } from 'react';

export default class extends Component {

    state = {
        show: false
    }

    makeText = (message, callback = null) => {
        this.setState({ message, show: true }, () => {
            this.timeoutId = setTimeout(() => {
                this.setState({ show: false }, callback)
            }, 1000);
        })
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId)
        }
    }

    render() {
        if (this.state.show) {
            return <div style={{ position: 'fixed', left: 0, right: 0, top: 0, bottom: 0, zIndex: 999999, fontSize: '18px', color: '#333', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ paddingLeft: '15px', paddingRight: '15px', lineHeight: '40px', backgroundColor: 'rgba(0,0,0,0.7)', borderRadius: '4px', color: '#fff' }}>{this.state.message}</div>
            </div>
        } else {
            return null;
        }
    }

}